import { Section } from "./Portfolio.styled";

import rewOne from "../../img/reviews-1.jpg";
import rewTwo from "../../img/reviews-2.jpg";
import rewThree from "../../img/reviews-3.jpg";

import portfolioOne from "../../img/portfolio-1.jpg";
import portfolioTwo from "../../img/portfolio-2.jpg";
import portfolioThree from "../../img/portfolio-3.jpg";
import portfolioFour from "../../img/portfolio-4.jpg";
import portfolioFive from "../../img/portfolio-5.jpg";
import portfolioSix from "../../img/portfolio-6.jpg";
import portfolioSeven from "../../img/portfolio-7.jpg";
import portfolioEight from "../../img/portfolio-8.jpg";

export const Portfolio = () => {
  return (
    <Section>
      <h1 className="title">Portfolio</h1>
      <div className="container-box">
        <div className="services__img-box">
          <img src={rewOne} alt="construction kitchen" />
          <img src={rewTwo} alt="construction bedroom" />
          <img src={rewThree} alt="construction dinner room" />
          <img src={portfolioOne} alt="construction dinner room" />
          <img src={portfolioTwo} alt="construction dinner room" />
          <img src={portfolioThree} alt="construction dinner room" />
          <img src={portfolioFour} alt="construction dinner room" />
          <img src={portfolioFive} alt="construction dinner room" />
          <img src={portfolioSix} alt="construction dinner room" />
          <img src={portfolioSeven} alt="construction dinner room" />
          <img src={portfolioEight} alt="construction dinner room" />
        </div>
      </div>
    </Section>
  );
};
